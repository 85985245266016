@import '../../../theme/newVariables';

.container {
  max-width: 679px;
  padding: var(--st-unit-2);
  padding-bottom: var(--st-unit-3);
  margin: 0 var(--st-unit-4);
  background-color: $az-grey-1;
  border-top: var(--st-unit-1) solid $az-light-orange;
  border-radius: var(--st-unit-1);
  color: $az-black-1;

  @media #{$medium-and-up} {
    max-width: 1099px;
    text-align: center;
  }

  @media #{$large-and-up} {
    max-width: 1154px;
    padding: var(--st-unit-3) var(--st-unit-4);
    margin: 0;
    text-align: left;
  }
}

.content {
  @media #{$large-and-up} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$large-and-up} {
    justify-content: center;
  }
}

.titleContainer {
  display: flex;
  margin-right: 0;
  align-items: center;
  justify-content: center;

  @media #{$large-and-up} {
    justify-content: flex-start;
  }

  @media #{$large-and-up} {
    margin-right: var(--st-unit-16);
  }

  img {
    margin-top: calc(-1 * var(--st-unit-10));
    margin-right: var(--st-unit-2);

    @media #{$large-and-up} {
      margin-top: 0;
      margin-right: var(--st-unit-3);
    }
  }

  .titleText {
    h2 {
      font-size: var(--st-unit-4-5);
      font-family: $font-az-cond;
      font-weight: 800;
      line-height: var(--st-unit-7);
      text-transform: uppercase;

      @media #{$medium-and-up} {
        font-size: var(--st-unit-5);
      }
    }

    p {
      font-size: var(--st-unit-4);
      margin: var(--st-unit-1) 0 var(--st-unit-3) 0;
      font-weight: 400;
      line-height: var(--st-unit-6);

      @media #{$large-and-up} {
        margin: 0;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  & :not(:first-child) {
    display: none;
  }

  @media #{$medium-and-up} {
    & :not(:first-child) {
      display: flex;
    }
  }

  & :nth-child(2) {
    margin: 0 var(--st-unit-4);
  }

  button {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 5px var(--st-unit-4);
    font-weight: 700;
    line-height: 20px;
    border-radius: 30px;
    color: #1d1d1d;
    background-color: $az-white;
    border: 1px solid $az-dark-black;

    @media #{$medium-and-up} {
      font-size: 14px;
      line-height: 22px;
    }

    img {
      display: inline-block;
      margin-right: var(--st-unit-1);
    }
  }
}

.nonUSContainer {
  @media #{$large-and-up} {
    margin-bottom: var(--st-unit-5);
  }

  .content {
    @media #{$large-and-up} {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .brTitleContainer {
      p,
      h2 {
        @media only screen and (max-width: 435px) {
          white-space: wrap;
        }
      }
    }

    .mxTitleContainer {
      .titleText {
        h2 {
          @media only screen and (min-width: #{$xsmall-screen-mid}) and (max-width: #{$large-screen}) {
            white-space: nowrap;
          }
        }

        p {
          max-width: 250px;
          white-space: wrap;

          @media #{$large-and-up} {
            max-width: unset;
          }
        }
      }
    }
  }
}

.pdp,
.nonShopContainer {
  margin: 0;

  @media #{$large-and-up} {
    max-width: 1560px;
  }
}

.nonShopContainer {
  margin-top: var(--st-unit-4);
  margin-bottom: var(--st-unit-4);
  padding: var(--st-unit-2);
  padding-bottom: var(--st-unit-3);

  @media #{$medium-and-up} {
    padding: var(--st-unit-6) var(--st-unit-5);
  }

  @media #{$large-and-up} {
    margin-top: var(--st-unit-10);
    margin-bottom: var(--st-unit-16);
  }

  .content {
    padding: 0;

    @media #{$medium-and-up} {
      padding: 0 17.5px;
    }
  }

  .titleContainer {
    @media #{$medium-and-up} {
      padding-right: 25px;
    }

    @media #{$large-and-up} {
      padding-right: 0;
    }

    img {
      margin-top: calc(-1 * var(--st-unit-10));

      @media #{$large-and-up} {
        margin-top: -29.5px;
      }
    }

    .titleText {
      text-align: center;

      @media only screen and (min-width: #{$xsmall-screen-mid}) and (max-width: #{$small-screen}) {
        white-space: nowrap;
      }

      h2 {
        font-size: var(--st-unit-4);
        line-height: var(--st-unit-5);

        @media only screen and (min-width: #{$xsmall-screen-up}) and (max-width: #{$small-screen}) {
          font-size: var(--st-unit-4-5);
          line-height: var(--st-unit-6);
        }

        @media #{$medium-and-up} {
          font-size: var(--st-unit-5);
          line-height: var(--st-unit-7);
        }

        @media #{$large-and-up} {
          font-size: var(--st-unit-7);
          line-height: var(--st-unit-9);
        }
      }

      p {
        line-height: var(--st-unit-5);

        @media only screen and (min-width: #{$xsmall-screen-up}) and (max-width: #{$small-screen}) {
          font-size: var(--st-unit-4);
          line-height: var(--st-unit-6);
        }

        @media #{$large-and-up} {
          font-size: var(--st-unit-4-5);
          line-height: 26px;
        }
      }
    }
  }
}

.hasVehicleContainer {
  padding: var(--st-unit-4);
  border-top: 3px solid $az-medium-orange-2;

  @media #{$large-and-up} {
    padding: var(--st-unit-6) var(--st-unit-4);
  }

  .underlinedButton {
    font-size: 14px;
    font-weight: 500;
    border: none;
    line-height: 19.6px;
    text-decoration: underline;
    padding: unset;
    width: fit-content;
    border-radius: unset;
    background-color: transparent;
  }

  .hasVehicleContent {
    text-align: left;

    @media #{$large-and-up} {
      display: flex;
      align-items: center;
    }

    .subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--st-unit-4);

      @media #{$large-and-up} {
        display: block;
        margin-bottom: unset;
      }

      p {
        font-size: var(--st-unit-4);
        font-family: $font-az-cond;
        font-weight: 800;
        line-height: var(--st-unit-5);
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media #{$large-and-up} {
          width: 125px;
          min-width: 125px;
          margin-right: var(--st-unit-6);
        }
      }

      button {
        margin-left: 5px;
        white-space: nowrap;

        @media #{$medium-and-up} {
          display: none;
        }
      }
    }

    .vehicleInfo {
      width: 100%;
      display: flex;
      padding: var(--st-unit-4) var(--st-unit-2);
      align-items: center;
      justify-content: space-between;
      background-color: $az-white;
      border-radius: var(--st-unit-1);

      @media #{$medium-and-up} {
        padding: var(--st-unit-4) var(--st-unit-6);
      }

      @media #{$large-and-up} {
        justify-content: flex-start;
      }

      .vehicleInfoDetails {
        display: flex;
        max-width: 584px;
        align-items: center;

        @media #{$large-and-up} {
          margin-right: var(--st-unit-8);
        }

        img {
          margin-right: var(--st-unit-4);

          @media #{$large-and-up} {
            margin-right: var(--st-unit-8);
          }
        }

        h2 {
          font-size: var(--st-unit-4);
          font-weight: 700;
          line-height: 22.4px;

          @media #{$large-and-up} {
            font-size: var(--st-unit-4-5);
            line-height: 25.2px;
          }
        }

        p {
          font-size: var(--st-unit-3);
          line-height: 16.8px;
          margin-top: var(--st-unit-1);
          font-weight: 400;

          @media #{$large-and-up} {
            font-size: 14px;
            line-height: 19.6px;
          }

          span {
            color: $az-grey-14;
          }
        }
      }

      .vehicleInfoActions {
        display: none;

        @media #{$medium-and-up} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        button {
          margin: 0;

          &:last-of-type {
            margin-top: 10px;
            line-height: 19.6px;
            color: $az-grey-6;
          }
        }
      }
    }
  }
}
